

























import {SfButton, SfSearchBar} from '@storefront-ui/vue';
import {
  defineComponent, ref, watch, useRoute, useContext,
} from '@nuxtjs/composition-api';
import {debounce} from 'lodash-es';
import SvgImage from '~/components/General/SvgImage.vue';
import {Products} from '~/modules/GraphQL/types';
import useFacet from "~/modules/catalog/category/composables/useFacet";

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
  },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    inputStyles: {
      type: Object,
      default() {
        return {}
      }
    },
    placeholder: {
      type: String,
      default() {
        return 'Search for items';
      }
    }
  },
  emits: ['set-is-open', 'set-search-results'],
  setup(props, {emit}) {
    const term = ref('');
    const loading = ref(false);
    const {
      search,
      result
    } = useFacet();

    const route = useRoute();

    const {$vsf} = useContext();

    const showSearch = () => {
      //@ts-ignore
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };

    const hideSearch = () => {
      //@ts-ignore
      if (props.isSearchOpen) {
        term.value = '';
        emit('set-is-open', false);
        emit('set-search-results', {
          results: [],
          term: ''
        });
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      //@ts-ignore
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      loading.value = true;

      term.value = searchTerm;

      //@ts-ignore
      if (term.value.length < props.minTermLen) {
        return emit('set-search-results', {
          results: [],
          term: term.value
        });
      }

      // M2-579
      const productList: Products = await search({
        //@ts-ignore
        pageSize: props.itemsPerPage,
        term: term.value,
        sort: 'search'
      }) as unknown as Products;

      emit('set-search-results', {
        results: result.value?.data?.items,
        term: term.value
      });

      loading.value = false;
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      loading
    };
  },
});

