
































































































































































import {defineComponent, PropType, ref, useContext, watch} from '@nuxtjs/composition-api';
import {CategoryTree, ProductInterface} from "~/modules/GraphQL/types";
import HeaderMenuItem from "~/components/Palmers/Header/HeaderMenuItem.vue";
import HeaderActions from "~/components/Palmers/Header/HeaderActions.vue";
import SearchBar from "~/components/Palmers/Search/SearchBar.vue";
import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";
import {useMenu} from "~/composables/Palmers";

export default defineComponent({
  name: "HeaderMenu",
  components: {
    HeaderMenuItem,
    HeaderActions,
    SearchBar,
    PictureItem,
    SearchResults: () => import("~/components/Palmers/Search/SearchResults.vue")
  },
  data: () => ({
    activeChildIndex: 0,
    showNavigationSubMenu: false,
    subMenuScroll: false,
    updated: 0,
    translate: 0,
    slideItems: [],
    itemsToScroll: 0,
    end: false,
    expectedWidth: 0,
    subMenuWidth: 0,
    isFixed: false,
    elementPosition: null,
  }),
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => []
    },
    getCatLink: {
      type: Function,
      require: true
    },
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    wishlistItemsCount: {
      type: Number,
      require: true
    },
    cartItemsCount: {
      type: Number,
      require: true
    },
    addProductToCart: {
      type: Function,
      require: true
    },
    isProductInCart: {
      type: Function,
      require: true
    },
    canAddProductToCart: {
      type: Function,
      require: true
    },
    addOrRemoveItemFromWishlist: {
      type: Function,
      require: true
    },
    isProductInWishlist: {
      type: Function,
      require: true
    },
  },
  watch: {
    '$route'() {
      if (this.categoryTree.length) {
        this.updated++;
      }
      this.term = '';
      this.translate = 0;
      this.end = false;
      this.itemsToScroll = 0;
      this.slug = this.$route.params.slug;
      this.productSearchResults = [];
      this.hideNavigationMenu();
    },
    updated() {
      this.$nextTick(() => {
        this.subMenuSlider();
      })
    },
    activeIndex() {
      this.$nextTick(() => {
        this.subMenuSlider();
      })
    }
  },
  setup() {
    const {activeCategoryIndex} = useMenu();
    const context = useContext();
    const slug = ref(context.route.value.params?.slug);

    const activeIndex = ref(activeCategoryIndex.value);

    watch(activeCategoryIndex, () => {
      activeIndex.value = activeCategoryIndex.value;
    })

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const term = ref('');

    return {
      term,
      isSearchOpen,
      productSearchResults,
      slug,
      magentoUrl: context.$vsf.$magento.config.magentoBaseUrl,
      activeIndex,
      activeCategoryIndex
    }
  },
  updated() {
    if (this.updated === 0) {
      this.updated += 1;
    }
  },
  mounted() {
    if (process.client) {
      this.$nextTick(() => {
        window.addEventListener('resize', this.subMenuSlider);
        this.subMenuSlider();

        const element = this.$refs.subMenuAction as HTMLElement;
        if (element) {
          this.elementPosition = element.getBoundingClientRect().top + window.scrollY - 60;
        }
        this.addScrollListener();

        document.body.dispatchEvent(new CustomEvent("hydrate"));
      })
    }
  },
  computed: {
    shouldDisplaySubMenu() {
      //@ts-ignore
      return this.isChildrenMenu(this.categoryTree[this.activeIndex]).length > 0;
    },
  },
  beforeDestroy() {
    this.removeScrollListener();
    window.removeEventListener('resize', this.subMenuSlider);
  },
  methods: {
    addScrollListener() {
      window.addEventListener('scroll', this.handleScroll);
    },
    removeScrollListener() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    handleScroll() {
      if (this.elementPosition === null && this.$refs.subMenuAction) {
        const element = this.$refs.subMenuAction as HTMLElement;
        this.elementPosition = element.getBoundingClientRect().top + window.scrollY + 50;
      }
      const top = window.scrollY || window.pageYOffset;
      this.isFixed = top >= (this.elementPosition + 50);
    },
    subMenuSlider() {
      const subMenu = <Element>this.$refs['sub-menu-level-2'];
      if (subMenu) {
        const subMenuItems = subMenu.querySelectorAll('.header-menu-item');

        //@ts-ignore
        this.slideItems = subMenuItems;

        this.expectedWidth = 0;

        for (let i = 0; i < subMenuItems.length; i++) {
          this.expectedWidth += subMenuItems[i].clientWidth;
        }

        this.subMenuWidth = subMenu.clientWidth;

        this.subMenuScroll = this.expectedWidth > this.subMenuWidth;
      }
    },
    menuSlide(to) {
      if (to === 'left') {
        this.end = false;

        if (this.itemsToScroll !== 0) {
          this.itemsToScroll -= 1;
        } else {
          this.translate = 0;
        }

        if (this.translate !== 0) {
          this.translate += (this.slideItems[this.itemsToScroll].clientWidth);
        }
      } else {
        this.translate -= (this.slideItems[this.itemsToScroll].clientWidth);
        this.itemsToScroll += 1;

        if ((this.expectedWidth + this.translate) < this.subMenuWidth) {
          this.end = true;
        }
      }
    },
    showNavigationMenu(index) {
      this.showNavigationSubMenu = true;
      this.activeChildIndex = index;
    },
    hideNavigationMenu() {
      this.showNavigationSubMenu = false;
    },
    reformatMenuPerRow(menu, columns) {
      const newMenu = [];
      const numRows = Math.ceil(menu.length / columns);

      for (let i = 0; i < numRows; i++) {
        const row = [];

        for (let j = 0; j < columns; j++) {
          const index = i * columns + j;
          if (index < menu.length) {
            row.push(menu[index]);
          }
        }

        newMenu.push(row);
      }

      return newMenu;
    },
    setSearchResult($event) {
      if (!$event) return $event;

      if ($event.hasOwnProperty('term')) {
        this.term = $event.term;
      }

      if ($event.hasOwnProperty('results')) {
        this.productSearchResults = $event.results;
      }
    },
    isScrolled() {
      return this.subMenuScroll && this.categoryTree[this.activeIndex] && this.categoryTree[this.activeIndex].children && this.categoryTree[this.activeIndex].children.length
    },
    getChildrenMenu(parent) {
      return parent && parent?.children && parent?.children?.length ? parent.children : []
    },
    isChildrenMenu(parent) {
      return parent && parent?.children && parent?.children?.length ? parent.children.filter((category) => category.include_in_menu) : []
    },
    isMegaMenu(parent) {
      return parent?.megamenu_is_available;
    }
  }
});

