



















import {defineComponent} from '@nuxtjs/composition-api';
import HeaderMenuItem from "~/components/Palmers/Header/HeaderMenuItem.vue";

export default defineComponent({
  name: "HeaderMenuItem",
  components: {
    HeaderMenuItem
  },
  props: {
    item: {
      type: Object,
      require: true
    },
    getCatLink: {
      type: Function,
      require: true
    }
  },
  methods: {
    getMenuItemClass(item) {
      return item.name.replaceAll(' ', '-').toLowerCase();
    },
    coinShopUrl(item) {
      return item?.['coin_shop_url'] || this.getCatLink(item);
    }
  }
});

